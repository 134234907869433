export const getChartDimensions = () => {
  if (window.innerWidth <= 1366) {
    return { width: 300, height: 300 };
  } else if (window.innerWidth <= 1024) {
    return { width: 600, height: 350 };
  } else if (window.innerWidth <= 768) {
    return { width: 800, height: 450 };
  } else {
    return { width: 500, height: 300 }; // Default dimensions
  }
};
